/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import SectionTitleText from '@common/SectionTitleText'
import {
  ParagraphWrapper,
  SubHeaderWrapper,
  ListWrapper,
  WarningWrapper,
} from '@common/CommonDisclamerPagesStyles'

import DisclamerPagesCommonContainer from '@containers/DisclamerPagesCommonContainer'

const IndexPage = ({ location }) => {
  return (
    <DisclamerPagesCommonContainer location={location}>
      <SectionTitleText
        text='Privacy Policy for Onlinepunks'
        extraStyles='width:100%;'
      />
      <ParagraphWrapper>
        At onlinepunks.com, accessible from onlinepunks.com, one of our main
        priorities is the privacy of our visitors. This Privacy Policy document
        contains types of information that is collected and recorded by
        onlinepunks.com and how we use it.
      </ParagraphWrapper>
      <ParagraphWrapper>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.
      </ParagraphWrapper>
      <SubHeaderWrapper>
        General Data Protection Regulation (GDPR)
      </SubHeaderWrapper>
      <ParagraphWrapper>
        We are a Data Controller of your information.
      </ParagraphWrapper>
      <WarningWrapper>
        Onlinepunks legal basis for collecting and using the personal
        information described in this Privacy Policy depends on the Personal
        Information we collect and the specific context in which we collect the
        information:
      </WarningWrapper>
      <ListWrapper>
        <li>Onlinepunks needs to perform a contract with you</li>
        <li>You have given Onlinepunks permission to do so</li>
        <li>
          Processing your personal information is in Onlinepunks legitimate
          interests
        </li>
        <li>Onlinepunks needs to comply with the law</li>
      </ListWrapper>
      <ParagraphWrapper>
        Onlinepunks will retain your personal information only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use your information to the extent necessary to comply with
        our legal obligations, resolve disputes, and enforce our policies.
      </ParagraphWrapper>
      <ParagraphWrapper>
        If you are a resident of the European Economic Area (EEA), you have
        certain data protection rights. If you wish to be informed what Personal
        Information we hold about you and if you want it to be removed from our
        systems, please contact us. Our Privacy Policy was generated with the
        help of{' '}
        <a href='https://www.gdprprivacypolicy.net/'>
          GDPR Privacy Policy Generator
        </a>{' '}
        and the{' '}
        <a href='https://www.app-privacy-policy.com'>
          App Privacy Policy Generator
        </a>
        .
      </ParagraphWrapper>
      <WarningWrapper>
        In certain circumstances, you have the following data protection rights:
      </WarningWrapper>
      <ListWrapper>
        <li>
          The right to access, update or to delete the information we have on
          you.
        </li>
        <li>The right of rectification.</li>
        <li>The right to object.</li>
        <li>The right of restriction.</li>
        <li>The right to data portability</li>
        <li>The right to withdraw consent</li>
      </ListWrapper>
      <SubHeaderWrapper>Log Files</SubHeaderWrapper>
      <ParagraphWrapper>
        onlinepunks.com follows a standard procedure of using log files. These
        files log visitors when they visit websites. All hosting companies do
        this and a part of hosting services' analytics. The information
        collected by log files include internet protocol (IP) addresses, browser
        type, Internet Service Provider (ISP), date and time stamp,
        referring/exit pages, and possibly the number of clicks. These are not
        linked to any information that is personally identifiable. The purpose
        of the information is for analyzing trends, administering the site,
        tracking users' movement on the website, and gathering demographic
        information.
      </ParagraphWrapper>
      <SubHeaderWrapper>Cookies and Web Beacons</SubHeaderWrapper>
      <ParagraphWrapper>
        Like any other website, onlinepunks.com uses 'cookies'. These cookies
        are used to store information including visitors' preferences, and the
        pages on the website that the visitor accessed or visited. The
        information is used to optimize the users' experience by customizing our
        web page content based on visitors' browser type and/or other
        information.
      </ParagraphWrapper>
      <SubHeaderWrapper>Google DoubleClick DART Cookie</SubHeaderWrapper>
      <ParagraphWrapper>
        Google is one of a third-party vendor on our site. It also uses cookies,
        known as DART cookies, to serve ads to our site visitors based upon
        their visit to www.website.com and other sites on the internet. However,
        visitors may choose to decline the use of DART cookies by visiting the
        Google ad and content network Privacy Policy at the following URL –{' '}
        <a href='https://policies.google.com/technologies/ads'>
          https://policies.google.com/technologies/ads
        </a>
      </ParagraphWrapper>
      <SubHeaderWrapper>Our Advertising Partners</SubHeaderWrapper>
      <ParagraphWrapper>
        Some of advertisers on our site may use cookies and web beacons. Our
        advertising partners are listed below. Each of our advertising partners
        has their own Privacy Policy for their policies on user data. For easier
        access, we hyperlinked to their Privacy Policies below.
      </ParagraphWrapper>
      <ListWrapper>
        <li>
          <ParagraphWrapper>Google</ParagraphWrapper>
          <ParagraphWrapper>
            <a href='https://policies.google.com/technologies/ads'>
              https://policies.google.com/technologies/ads
            </a>
          </ParagraphWrapper>
        </li>
      </ListWrapper>
      <SubHeaderWrapper>Privacy Policies</SubHeaderWrapper>
      <ParagraphWrapper>
        You may consult this list to find the Privacy Policy for each of the
        advertising partners of onlinepunks.com.
      </ParagraphWrapper>
      <ParagraphWrapper>
        Third-party ad servers or ad networks uses technologies like cookies,
        JavaScript, or Web Beacons that are used in their respective
        advertisements and links that appear on onlinepunks.com, which are sent
        directly to users' browser. They automatically receive your IP address
        when this occurs. These technologies are used to measure the
        effectiveness of their advertising campaigns and/or to personalize the
        advertising content that you see on websites that you visit.
      </ParagraphWrapper>
      <ParagraphWrapper>
        Note that onlinepunks.com has no access to or control over these cookies
        that are used by third-party advertisers.
      </ParagraphWrapper>
      <SubHeaderWrapper>Third Party Privacy Policies</SubHeaderWrapper>
      <ParagraphWrapper>
        onlinepunks.com's Privacy Policy does not apply to other advertisers or
        websites. Thus, we are advising you to consult the respective Privacy
        Policies of these third-party ad servers for more detailed information.
        It may include their practices and instructions about how to opt-out of
        certain options. You may find a complete list of these Privacy Policies
        and their links here: Privacy Policy Links.
      </ParagraphWrapper>
      <ParagraphWrapper>
        You can choose to disable cookies through your individual browser
        options. To know more detailed information about cookie management with
        specific web browsers, it can be found at the browsers' respective
        websites. What Are Cookies?
      </ParagraphWrapper>
      <SubHeaderWrapper>Children's Information</SubHeaderWrapper>
      <ParagraphWrapper>
        Another part of our priority is adding protection for children while
        using the internet. We encourage parents and guardians to observe,
        participate in, and/or monitor and guide their online activity.
      </ParagraphWrapper>
      <ParagraphWrapper>
        onlinepunks.com does not knowingly collect any Personal Identifiable
        Information from children under the age of 13. If you think that your
        child provided this kind of information on our website, we strongly
        encourage you to contact us immediately and we will do our best efforts
        to promptly remove such information from our records.
      </ParagraphWrapper>
      <SubHeaderWrapper>Online Privacy Policy Only</SubHeaderWrapper>
      <ParagraphWrapper>
        Our Privacy Policy created at GDPRPrivacyPolicy.net) applies only to our
        online activities and is valid for visitors to our website with regards
        to the information that they shared and/or collect in onlinepunks.com.
        This policy is not applicable to any information collected offline or
        via channels other than this website.{' '}
        <a href='https://gdprprivacypolicy.net'>Our GDPR Privacy Policy</a> was
        generated from the GDPR Privacy Policy Generator.
      </ParagraphWrapper>
      <SubHeaderWrapper>Consent</SubHeaderWrapper>
      <ParagraphWrapper>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </ParagraphWrapper>
    </DisclamerPagesCommonContainer>
  )
}

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default IndexPage
